import React, { useState, useEffect } from "react";
import Spinner from "../helper/Spinner";
const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  return (
    <>
      {!loading ? (
        <div className="About">
          <section className="about-section">
            <div className="container">
              <div className="row">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                  <div className="inner-column">
                    <div className="sec-title">
                      <h1 className="title">About Us</h1>
                      <h3 className="tag-line">
                        I believe in MERNifying the Web, one stack at a time!
                      </h3>
                    </div>
                    <div className="text">
                      Hey, I am Saikat Santra, I am no one but just a simple boy
                      who dreams big, who tries to see tomorrow in today.
                      Curiosity is the mother of invention, so if I could find
                      today's need, I would have serve humanity porperly. In
                      today's rapidly evolving digital landscape, creating
                      robust and immersive web applications has become paramount
                      for businesses seeking to stand out and engage their
                      audience effectively. As a full stack MERN developer, I
                      have witnessed firsthand the immense power and versatility
                      of the MERN stack (MongoDB, Express.js, React.js, and
                      Node.js) in transforming the way we build and deploy web
                      applications.
                    </div>
                    <div className="text">
                      Contact Details:-
                      <br />
                      Address: Khalna, purba khalna, near khalna bridge, Howrah,
                      West Bengal, Pin - 711413
                      <br />
                      Mobile: +91 7076008951
                      <br />
                      Alternate: +91 6283547719
                      <br />
                      Email: saikatsantra2050@gmail.com
                      <br />
                    </div>
                    <div className="btn-box">
                      <a
                        href="mailto:saikatsantra2050@gmail.com"
                        className="theme-btn btn-style-one"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column wow fadeInLeft">
                    <div className="author-desc">
                      <h2>Saikat Santra</h2>
                      <span>MERN Developer</span>
                    </div>
                    <figure className="image-1">
                      <img
                        title="Saikat Santra"
                        src="https://i.ibb.co/QP6Nmpf/image-1-about.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default About;
