import Spinner from "../helper/Spinner";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";

const Hire = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.sendForm(
        process.env.REACT_APP_MAIL_SERVICE_ID,
        process.env.REACT_APP_MAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_MAIL_USER_ID
      );
      alert("Your response is saved");
      // window.location.reload();
      window.location.assign("/");
    } catch (err) {
      console.log(err);
      alert((err?.text && err?.text !== "") ? err?.text : "Some Unexpected Error Occurred");
      window.location.reload();
    }
  };

  return (
    <>
      {!loading ? (
        <div className="hire-me">
          <section className="about-section">
            <div className="container">
              <div className="row">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating input-form mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Firstname Lastname"
                        value={name}
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <label for="floatingInput">Name</label>
                    </div>
                    <div className="form-floating input-form">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="abc@domain.com"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label for="floatingInput">Email</label>
                    </div>
                    <div className="form-floating input-form">
                      <input
                        type="number"
                        pattern="[6-9]{1}[0-9]{9}"
                        className="form-control"
                        id="floatingInput"
                        placeholder="1234567890"
                        value={mobile}
                        name="mobile"
                        onChange={(e) => setMobile(e.target.value)}
                        required
                      />
                      <label for="floatingInput">Contact Details</label>
                    </div>
                    <div className="input-group input-form">
                      <span className="input-group-text">Message</span>
                      <textarea
                        className="form-control"
                        aria-label="Message"
                        required
                        value={body}
                        name="body"
                        onChange={(e) => setBody(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="button-on">
                      <button
                        type="submit"
                        className="btn btn-warning submit-button"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column wow fadeInLeft">
                    <div className="author-desc">
                      <h2>Saikat Santra</h2>
                      <span>MERN Developer</span>
                    </div>
                    <figure className="image-1">
                      <img
                        title="Saikat Santra"
                        src="https://img.etimg.com/thumb/msid-89631138,width-1440,height-1080,imgsize-70174,resizemode-75/small-biz/entrepreneurship/satellites-are-generating-tonnes-of-data-in-space-beaming-it-back-to-earth-is-a-problem.jpg"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Hire;
