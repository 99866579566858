import React, { useState, useEffect } from "react";
import Spinner from "../helper/Spinner";
const Portfolio = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);
  return (
    <>
      {!loading ? (
        <div className="portfolio">
          <h1 className="project-title">My Projects</h1>
          <div className="projects-container">
            <ul className="projects-cards">
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Rent-On</h3>
                  <div className="projects-card-content">
                    <p>
                      A MERN Stack Web Application for Renting Anything and
                      Everything
                    </p>
                    <p>
                      Rent-On is a comprehensive online platform designed to
                      facilitate the renting process for both individuals who
                      want to rent items and those who wish to lend their
                      belongings. This MERN stack web application provides a
                      user-friendly interface that enables seamless transactions
                      and ensures a convenient experience for both renters and
                      rentees.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/Rent-On-frontend"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Park-In</h3>
                  <div className="projects-card-content">
                    <p>
                      A Node.js, Express.js, React, and PostgreSQL Web
                      Application for Easy Parking Management
                    </p>
                    <p>
                      Park-In is an innovative web application designed to
                      simplify parking management and streamline the ticketing
                      process. Built using Node.js, Express.js, React, and
                      PostgreSQL, Park-In offers a fast, user-friendly, and
                      highly compatible solution for both parking administrators
                      and users.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/park-in-backend"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Medi-Mate</h3>
                  <div className="projects-card-content">
                    <p>
                      A MERN Stack Web Application for Effective Healthcare
                      Management
                    </p>
                    <p>
                      Medi-Mate is a comprehensive MERN stack web application
                      that aims to streamline healthcare management by offering
                      a range of features to facilitate doctor appointments,
                      ambulance services, hospital guidance, and the
                      organization of healthcare camps and donations. It
                      provides a user-friendly interface to ensure easy access
                      and efficient utilization of healthcare resources.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/Medi-mate-frontend"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Auction House</h3>
                  <div className="projects-card-content">
                    <p>
                      A MERN Stack Web Application for Digital and Physical
                      Auctions
                    </p>
                    <p>
                      Auction House is a feature-rich MERN stack web application
                      designed to provide a seamless and efficient platform for
                      conducting auctions, whether they are digital or physical.
                      It serves as a hub for buyers and sellers, facilitating
                      the process of selling and acquiring various items through
                      bidding and auction events. With user-friendly features
                      and secure transactions, Auction House simplifies the
                      auction process and ensures a seamless experience for all
                      users.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/Auction-House"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Boss.dk</h3>
                  <div className="projects-card-content">
                    <p>
                      A Node.js, Express.js, React.js, and PostgreSQL Web
                      Application for Effective Time Management and Task
                      Reminders
                    </p>
                    <p>
                      Boss.dk is a powerful web application built to enhance
                      time management, provide effective task reminders, and
                      help users plan their entire day efficiently. Developed
                      using Node.js, Express.js, React.js, and PostgreSQL,
                      Boss.dk offers a comprehensive solution for individuals
                      seeking better control over their daily schedules.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/Boss.dk-frontend"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Saktimata</h3>
                  <div className="projects-card-content">
                    <p>
                      A MERN Stack Web Application for Hardware and Builders
                      Shop Management
                    </p>
                    <p>
                      Saktimata is a powerful MERN stack web application
                      specifically developed for managing the online selling
                      operations of a hardware and builders shop. It provides a
                      comprehensive solution for inventory management, receipt
                      generation, and employee management, streamlining various
                      aspects of the shop's operations.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/saktimata"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">HiveHR</h3>
                  <div className="projects-card-content">
                    <p>
                      A Node.js, Express.js, React.js, and PostgreSQL Web
                      Application for Human Resource Management
                    </p>
                    <p>
                      HiveHR is a comprehensive web application built using
                      Node.js, Express.js, React.js, and PostgreSQL to
                      streamline various aspects of Human Resource management.
                      It offers a wide range of features including leave
                      calculation, payroll management, payslip generation, and
                      employee performance analysis, empowering organizations to
                      efficiently manage their human capital.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/hive-hr-backend"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">FDC</h3>
                  <div className="projects-card-content">
                    <p>
                      Node.js, MongoDB, Express.js, EJS Template Job Portal Web
                      Application
                    </p>
                    <p>
                      FDC is a robust job portal web application designed to
                      connect employers and job seekers on a single platform.
                      Leveraging the power of Node.js, MongoDB, Express.js, and
                      EJS templates, FDC offers a comprehensive set of features
                      including job listings, job applications, company reviews,
                      and feedback mechanisms.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/FDC"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Just Gossip</h3>
                  <div className="projects-card-content">
                    <p>
                      Node.js and Socket.IO Web Application for Chatting with
                      New People
                    </p>
                    <p>
                      Just Gossip is an exciting web application built using
                      Node.js and Socket.IO that provides a platform for users
                      to chat and connect with new people in real-time. With its
                      vibrant and interactive interface, Just Gossip offers
                      users a fun and engaging environment to strike up
                      conversations, make new friends, and indulge in
                      light-hearted discussions.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/Just-Gossip"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
              <li className="projects-card">
                <div className="project-card-body-structure">
                  <h3 className="projects-card-title">Task Keeper</h3>
                  <div className="projects-card-content">
                    <p>
                      Node.js, MongoDB, Express.js, EJS Template Web Application
                      for Task Management and Planning
                    </p>
                    <p>
                      Task Keeper is a powerful To-Do List web application
                      developed using Node.js, MongoDB, Express.js, and EJS
                      templates. With its user-friendly interface and robust
                      features, Task Keeper empowers users to effectively manage
                      and plan their tasks, ensuring a productive day.
                    </p>
                  </div>
                </div>
                <div className="projects-card-link-wrapper">
                  <a
                    href="https://github.com/Saikat2050/Task-Keeper"
                    className="projects-card-link"
                  >
                    Learn More
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Portfolio;
