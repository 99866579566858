import React, { useState, useEffect } from 'react'
import Spinner from "./helper/Spinner";
import Home from "./components/Home";
import About from "./components/AboutUs";
import Navbar from "./components/Navbar";
import Hire from "./components/Hire";
import Portfolio from "./components/Portfolio";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
  }, [])

  return (
    <>
      {!loading ? (
        <Router>
      <div className="App">
        
        <Navbar />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/hire-me" element={<Hire />} />
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes>
        </div>
      </div>
    </Router>
      ) : (
        <Spinner/>
      )}
  </>
  );
}

export default App;
