import React, { useState, useEffect } from "react";
import Spinner from "../helper/Spinner";
import me from "../imgsAndDocs/Home120.jpg";
import doc from "../imgsAndDocs/saikat_santra_resume-3.pdf";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      {!loading ? (
        <div className="home">
          <div className="intro">
            <h4 className="salutation">HELLO</h4>
            <div className="meIntro">
              <h1>I'm Saikat Santra</h1>
              {/* <h3>A Freelance MERN stack developer</h3> */}
              {/* <span className="description-animated-text">
                      <b className="default-text-visibility">A Freelance Developer</b>
                      <b>Expertise in MERN stack</b>
                      <b>Lead a very passionate team</b>
                      <b>Believes in Work hard and enjoy header</b>
                </span> */}
              <h3>
                <span className="description-animated-text"></span>
              </h3>
            </div>
            <div className="buttonGroups">
              <Link
                to="/hire-me"
                type="button"
                class="btn btn-outline-light"
                style={{
                  marginRight: "1%",
                }}
              >
                Hire Me
              </Link>
              <a
                href={doc}
                download="Saikat_Santra"
                target="_blank"
                rel="noreferrer"
                type="button"
                class="btn btn-outline-light"
                style={{
                  marginLeft: "1%",
                }}
              >
                View Resume
              </a>
            </div>
            <div className="social-icons">
              <span className="individual-icons">
                <a
                  className="after-span ripple"
                  href="mailto:saikatsantra2050@gmail.com"
                >
                  <FontAwesomeIcon
                    className="social-media-button-group-icon"
                    icon={faEnvelope}
                  />
                </a>
              </span>
              <span className="individual-icons">
                <a
                  className="after-span ripple"
                  href="https://www.instagram.com/mr.saikat_official/"
                >
                  <FontAwesomeIcon
                    className="social-media-button-group-icon"
                    icon={faInstagram}
                  />
                </a>
              </span>
              <span className="individual-icons">
                <a
                  className="after-span ripple"
                  href="https://www.facebook.com/saikat.santra.1238/"
                >
                  <FontAwesomeIcon
                    className="social-media-button-group-icon"
                    icon={faFacebookF}
                  />
                </a>
              </span>
              <span className="individual-icons">
                <a
                  className="after-span ripple"
                  href="https://github.com/Saikat2050"
                >
                  <FontAwesomeIcon
                    className="social-media-button-group-icon"
                    icon={faGithub}
                  />
                </a>
              </span>
              <span className="individual-icons">
                <a
                  className="after-span ripple"
                  href="https://twitter.com/Santra2050"
                >
                  <FontAwesomeIcon
                    className="social-media-button-group-icon"
                    icon={faTwitter}
                  />
                </a>
              </span>
              <span className="individual-icons">
                <a
                  className="after-span ripple"
                  href="https://www.linkedin.com/in/santra2050/"
                >
                  <FontAwesomeIcon
                    className="social-media-button-group-icon"
                    icon={faLinkedin}
                  />
                </a>
              </span>
            </div>
          </div>
          <div>
            <img src={me} alt="A boy sitting" className="meImg"></img>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Home;
