import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {
  const [isShow, setIsShow] = useState(true)

  const navEvent = () => {
    setIsShow(current => !current)

  }
  return (
    <div className="navBar">
      <button className="nav-btn" onClick={navEvent}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      {isShow && (<div className="new-main-nav">
      <ul className="new-nav">
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/about">ABOUT US</Link></li>
        <li><Link to="/portfolio">PORTFOLIO</Link></li>
        <li><Link to="/hire-me">HIRE ME</Link></li>
      </ul>
 </div>)}
    </div>
  );
};

export default Navbar;
